<script setup lang="ts">
import { NumberToString, USDollar, type ReconciliationField } from '@audit'
interface Props {
  value: ReconciliationField
}

const dayjs = useDayjs()

defineProps<Props>()
</script>

<template>
  <div class="flex flex-col gap-1 text-xs">
    <template
      v-if="
        value?.delta &&
        (value?.dataType === 'currency' || value?.dataType === 'number')
      "
    >
      <div v-if="(value?.delta as number) > 0" class="text-primary">
        {{
          `+${value?.dataType === 'currency' ? USDollar.format(value?.delta as number) : NumberToString.format(value.delta as number)}`
        }}
      </div>

      <div v-else-if="(value?.delta as number) < 0" class="text-red-500">
        {{
          `${value?.dataType === 'currency' ? USDollar.format(value?.delta as number) : NumberToString.format(value.delta as number)}`
        }}
      </div>
    </template>
    <div
      v-else-if="value?.delta && value?.dataType === 'string'"
      class="text-blue-500"
    >
      {{ value?.delta }}
    </div>
    <div
      v-else-if="value?.delta && value?.dataType === 'date'"
      class="text-blue-500"
    >
      {{ dayjs(value.delta).format('DD/MM/YYYY') }}
    </div>
    <template v-else>
      <span>-</span>
    </template>
  </div>
</template>
